<template>
  <div class="container_margin">
    <b-modal id="modal-center-attach-policies" centered hide-footer hide-header>
      <div class="row">
        <div class="col">
          <b>{{ "Attach Policy" }} </b>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col">
          <div class="row mt-2">
            <div class="col">
              <table class="table">
                <thead>
                  <tr>
                    <th scope="col" width="10%"></th>
                    <th scope="col" width="40%">{{ "Policy" }}</th>
                    <th scope="col" width="50%">{{ "Description" }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(item, index) in policies_to_attach"
                    :key="'policies_to_attach' + index"
                  >
                    <td width="10%">
                      <input
                        class="checkbox custom-checkbox"
                        :value="item"
                        type="checkbox"
                        v-model="attach_policies"
                      />
                    </td>

                    <td width="40%">
                      {{ item.name }}
                    </td>
                    <td width="50%">
                      {{ item.description }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-5"></div>
        <div class="col-3 float-right">
          <button class="btn btn-primary-outlined w-100">
            {{ "Cancel" }}
          </button>
        </div>
        <div class="col-4 float-right">
          <button class="btn btn-primary w-100">{{ "Attach Policies" }}</button>
        </div>
      </div>
    </b-modal>
    <div class="row">
      <div class="col-sm-12 col-md-4 col-lg-4 col-xl-8 mb-1"></div>
      <div class="col-sm-6 col-md-4 col-lg-4 col-xl-2 mb-1">
        <button class="btn btn-primary-outlined w-100 float-right">
          {{ "Cancel" }}
        </button>
      </div>
      <div class="col-sm-6 col-md-4 col-lg-4 col-xl-2 mb-1">
        <button class="btn btn-primary w-100 float-right">
          {{ "Update Changes" }}
        </button>
      </div>
    </div>

    <div class="row mt-1">
      <div class="col">
        <div class="card border shadow-sm p-3 mt-3 rounded">
          <div class="row">
            <div class="col">
              <h5 class="font-weight-bold">{{ "Role Details" }}</h5>
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-4">
              <b>{{ "Name" }}</b>
            </div>
            <div class="col-8">
              <b-form-input
                autocorrect="off"
                autocomplete="off"
                v-model="name"
                type="text"
                id="name"
                name="name"
              />
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-4">
              <b>{{ "Description" }}</b>
            </div>
            <div class="col-8">
                    <b-form-textarea
            id="description"
            v-model="description"
            rows="3"
            max-rows="6"
          ></b-form-textarea>
            </div>
          </div>

        </div>
      </div>
    
    </div>

    <div class="row mt-2">
      <div class="col">
        <div class="card border shadow-sm p-3 mt-3 rounded">
          <div class="row">
            <div class="col">
              <h5 class="font-weight-bold">
                {{ "Policy Details" }}
              </h5>
            </div>

            <div class="col-6 col-md-4 col-lg-4 col-xl-2">
              <button
                v-b-modal.modal-center-attach-policies
                class="float-right btn btn-primary-outlined w-100"
              >
                {{ "Add Policy" }}
              </button>
            </div>
          </div>

          <div class="row mt-2 table-card">
            <div class="col">
              <table class="table">
                <thead>
                  <tr>
                    <th scope="col" width="40%">{{ "Policy" }}</th>
                    <th scope="col" width="50%">{{ "Description" }}</th>
                    <th scope="col" width="10%">{{ "Action" }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(item, index) in policies_have"
                    :key="'policies_have' + index"
                  >
                    <!--   <td class="py-4">
                    <p class="font-weight-bold text-center text-muted"> Start Your Case Search 

                    </p>
                </td> -->

                    <td width="40%">
                      {{ item.name }}
                    </td>
                    <td width="50%">
                      {{ item.description }}
                    </td>
                    <td width="10%">
                      <button
                        @click="editRole"
                        class="btn-primary py-1 px-2"
                      >
                        {{ "Remove" }}
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
/* eslint-disable */
export default {
  data() {
    return {
      policies_to_attach: [], // Array to hold policies to attach
      attach_policies: [], // Array to store selected policies
      name: "", // Role name
      email: "", // Role email
      chosenStatus: "", // Selected status
      Status: ["Active", "Inactive"], // Status options
      password: "", // Password input
      confirm_password: "", // Confirm password input
      policies_have: [], // Array to hold policies already assigned
    };
  },
  methods: {
    editRole() {
      // Method to handle removing Role policies
    },
    attachPolicies() {
      // Method to handle attaching Policies to the Role
    },
    cancelAttachPolicies() {
      // Method to handle canceling the role attachment modal
    },
    updatePassword() {
      // Method to handle updating the Role's password
    },
    cancelUpdate() {
      // Method to handle canceling the updates
    },
  },
};
</script>
<style scoped>
.container_margin {
  padding: 2rem;
}
</style>